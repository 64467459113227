.myButton {
    transition: transform 0.1s; /* smooth transition for scaling */
  }
  
  .myButton:active { /* when button is clicked */
    transform: scale(0.95); /* button is slightly reduced in size */
  }
  
  /* Default styles for larger screens */
.video_show {
  width: 560px;
  height: 315px;
}

/* Responsive styles for screens with a max-width of 768px (mobile view) */
@media (max-width: 768px) {
  .video_show {
    width: 320px;
  }
}
